@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";
@use "@mixins/typography.scss";

@use "sass:color";

.emphasize {
  font-weight: 500;
}

.descriptionList {
  @include typography.body2;
  background-color: lighten(colors.$primary-light, 26);
  border-radius: 12px;
  list-style: none;
  margin: theme.spacing(1.5, 0);
  padding: theme.spacing(1, 2, 1, 2.5);

  & li {
    display: flex;
    list-style: none;
    margin: theme.spacing(1.5, 0);
    padding: 0;
  }

  & svg {
    color: colors.$primary;
    flex-shrink: 0;
    height: 18px;
    margin: theme.spacing(0.25, 1.5, 0, 0);
    opacity: 0.8;
    width: 18px;

    &.inlineIcon {
      height: 14px;
      margin: theme.spacing(0, 0.25);
      transform: translateY(2px);
      width: 14px;
    }
  }
}

.popperCopy {
  margin: theme.spacing(1, 0, 0);
}

.priorityIcon {
  color: colors.$primary;
  height: 16px;
  margin: theme.spacing(0, 0.25);
  transform: translateY(2px);
  width: 16px;
}

.gcalIcon {
  height: 16px;
  width: 16px;
}

.count {
  @include typography.caption;
  padding: 0px 4px;
  background-color: transparentize(colors.$black, 0.91);
  border-radius: 4px;
}
