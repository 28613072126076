@mixin idleStateMod {
  &--idle {
    @content;
  }
}

@mixin runningStateMod {
  &--running {
    @content;

    &:hover,
    &:active {
      @content;
    }
  }
}

@mixin completeStateMod {
  &--complete {
    @content;

    &:hover,
    &:active {
      @content;
    }
  }
}

@mixin errorStateMod {
  &--error {
    @content;

    &:hover,
    &:active {
      @content;
    }
  }
}
