@use "@functions/theme.scss";
@use "@mixins/animations.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@mixins/promiseState.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";
@use "@variables/typography.scss" as typo-vars;

.root {
  transition: theme.transition((color, background-color));

  &--link {
    &:hover {
      text-decoration: none;
    }
  }

  &--destructive {
    color: colors.$error;
  }

  @include promiseState.runningStateMod {
    @include animations.opacity-pulse;
  }

  @include promiseState.completeStateMod {
    background: colors.$success;
    color: colors.$white;
  }

  @include promiseState.errorStateMod {
    background: colors.$error;
    color: colors.$white;
  }
}

.icon {
  width: 1em;
  height: 1em;
}
