@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";

.root {
  &--active {
    transform: scaleY(-1);
  }
}
