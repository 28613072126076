@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";

.root {
  vertical-align: inherit;
  font-size: 16px;

  &--grey {
    color: colors.$grey-600;
  }

  &--primary {
    color: colors.$primary;
  }

  &--secondary {
    color: colors.$secondary;
  }

  &--error {
    color: colors.$error;
  }

  &--warn {
    color: colors.$warning-light;
  }

  &--success {
    color: colors.$success;
  }
}

.tooltip {
  & p {
    margin-bottom: 0;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.icon {
  font-size: inherit;

  &--superscript {
    font-size: 0.6em;
    cursor: pointer;
  }

  &--emoji {
    display: inline-block;
    width: 1em;
    height: 1em;
    line-height: 1em;
    transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    flex-shrink: 0;
    user-select: none;
    font-size: 1.5rem;
  }
}
