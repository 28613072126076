@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";
@use "@mixins/typography.scss";

.fixButton {
  padding: theme.spacing(0, 0, 0.3, 0);
  min-width: 30px;
}
