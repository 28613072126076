@use "@functions/theme.scss";
@use "@mixins/animations.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@mixins/typography.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";
@use "@variables/typography.scss" as typo-vars;

.root {
  position: relative;

  &:after {
    background: linear-gradient(229deg, rgba(82, 99, 243, 0.1), rgba(82, 99, 243, 0) 70.71%),
      linear-gradient(50deg, rgba(238, 154, 178, 0.25), rgba(238, 154, 178, 0) 70.71%),
      linear-gradient(334deg, rgba(82, 99, 243, 0.1), rgba(82, 99, 243, 0) 70.71%);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  img,
  video {
    border-radius: 6px 6px 0 0;
    display: block;
    width: 100%;
  }
}
