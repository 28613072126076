@use "@functions/utils.scss";

@mixin opacity-pulse($from: 0.5, $to: 0.15) {
  $name: opacity_pulse_keyframes__#{utils.str-replace("#{$from}", ".", "_")}__#{utils.str-replace("#{$to}", ".", "_")};

  @keyframes #{$name} {
    from {
      opacity: $from;
    }
    to {
      opacity: $to;
    }
  }
  animation: #{$name} 1s infinite alternate;
}
