@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";
@use "@variables/typography.scss" as typoVars;
@use "@mixins/typography.scss";

.root {
  margin-bottom: theme.spacing(2.5);
  width: 100%;
}
.content {
  display: flex;
}
.vertical {
  flex-direction: column;
  gap: 0;
}
.horizontal {
  flex-direction: row;
  gap: theme.spacing(1);
  justify-content: center;
}
