@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";

@use "@variables/typography.scss" as typoVars;
@use "@mixins/typography.scss";

.root {
  margin: 0 auto;
  max-width: 640px;
  padding: theme.spacing(5, 2);
  text-align: center;
  width: 100%;

  &--large {
    max-width: 900px;

    .subtitle {
      margin-left: auto;
      margin-right: auto;
      max-width: 60ch;
    }
  }

  &--small {
    .iconWrap {
      min-height: 54px;
      width: 54px;

      &__icon {
        min-height: 26px;
        width: 26px;
      }
    }
  }

  &--extraSmall {
    .iconWrap {
      min-height: 44px;
      width: 44px;

      &__icon {
        min-height: 24px;
        width: 24px;
      }
    }
  }

  &--error {
    .iconWrap {
      background-color: lighten(colors.$error-light, 0.75);

      &__icon {
        color: lighten(colors.$error-main, 0.35);
      }
    }
  }

  &--corn {
    .iconWrap {
      background-color: colors.$corn-200;

      &__icon {
        color: colors.$corn;
      }
    }
  }
}

.centered {
  @include breakpoints.up("sm") {
    align-items: center;
    display: flex;
    min-height: 80%;
  }
}

.iconWrap {
  align-items: center;
  background-color: lighten(colors.$primary-light, 0.7);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin: theme.spacing(0, auto, 2);
  min-height: 72px;
  width: 72px;

  &__icon {
    color: colors.$primary;
    height: 32px;
    width: 32px;
  }
}

.subtitle {
  @include typography.subtitle1;
  margin: theme.spacing(1, 3);
}

.actions {
  display: flex;
  gap: theme.spacing();
  justify-content: center;
  margin-top: theme.spacing(3);
}
