@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";

.root {
  z-index: 1203;
}

.paper {
  background-color: colors.$white;
  border-radius: theme.border-radius(2);
  z-index: 2;
}

.content {
  position: relative;
  max-width: 340px;
  padding: theme.spacing(4, 2, 2, 2);
}

.closeBtn {
  position: absolute;
  top: theme.spacing(0.25);
  right: theme.spacing(0.25);
  margin: 0 0 0 auto;
  min-width: 0;
}