@use "@functions/theme.scss";
@use "@mixins/breakpoints.scss";
@use "@mixins/shadows.scss";
@use "@variables/colors.scss";
@use "@variables/shape.scss";
@use "@variables/typography.scss" as typoVars;
@use "sass:color";

$LABEL_DIMENSIONS: 36;

.root {
  @include breakpoints.up("sm") {
    margin-top: -5px;
  }

  :global(.MuiDateCalendar-root),
  :global(.MuiDateRangeCalendar-root) {
    justify-content: flex-start;
    background-color: inherit;
    width: auto;
    max-width: 100%;
    min-width: 0;
    @include breakpoints.up("sm") {
      margin: 0 auto;
    }
    @include breakpoints.up("md") {
      margin: 0;
      max-width: none;
      overflow: visible;
    }
  }

  :global(.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition) {
    min-height: 255px;
  }

  :global(.MuiPickersCalendarHeader-root) {
    margin: theme.spacing(1, auto, 0);
    max-width: 260px;
    padding: theme.spacing(0, 0, 0, 1);
    width: 100%;
  }

  :global(.MuiPickersCalendarHeader-label) {
    margin-left: theme.spacing(0.75);
  }
  :global(.MuiPickersArrowSwitcher-root) {
    margin-right: theme.spacing(0.5);

    & span {
      font-size: 14px;
      font-weight: typoVars.$font-weight-medium;
    }
  }
  :global(.MuiPickersCalendarHeader-root .MuiPickersArrowSwitcher-button) {
    color: colors.$primary;
    padding: theme.spacing(0.5);
  }
  :global(.MuiPickersCalendarHeader-root .MuiPickersArrowSwitcher-button:disabled) {
    color: colors.$grey-500;
  }
  :global(.MuiDayCalendar-weekDayLabel) {
    height: $LABEL_DIMENSIONS + px;
    width: $LABEL_DIMENSIONS + px;
    color: colors.$text-primary;
    margin: 0;
  }

  :global(.MuiDayCalendar-weekContainer) {
    justify-content: center;
  }

  // selectable
  :global(.MuiPickersDay-root) {
    background-color: lighten(colors.$primary, 34.5%);
    border: 2px solid colors.$white;
    color: colors.$primary;
    flex-shrink: 0;
    font-weight: typoVars.$font-weight-medium;
    height: $LABEL_DIMENSIONS + px;
    margin: 0;
    width: $LABEL_DIMENSIONS + px;
    transform: scale(1);
  }

  :global(.MuiPickersDay-root):hover {
    background-color: lighten(colors.$primary, 30%);
  }

  // today
  :global(.MuiPickersDay-root.MuiPickersDay-today) {
    position: relative;
  }
  :global(.MuiPickersDay-root.MuiPickersDay-today:after) {
    background-color: colors.$primary;
    border-radius: 50%;
    bottom: 6px;
    content: "";
    height: 4px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    width: 4px;
  }

  // selected
  :global(.MuiPickersDay-root.Mui-selected),
  :global(.MuiPickersDay-root.Mui-selected.MuiPickersDay-today),
  :global(.MuiPickersDay-root.Mui-selected:hover),
  :global(.MuiPickersDay-root.Mui-selected.MuiPickersDay-today:hover) {
    background-color: colors.$primary;
    color: colors.$white;
  }
  :global(.MuiPickersDay-root.Mui-selected:after),
  :global(.MuiPickersDay-root.Mui-selected.MuiPickersDay-today:after) {
    background-color: colors.$white;
  }
  // Initial load dont show current day as selected
  :global(.MuiPickersDay-root.Mui-selected.Mui-disabled),
  :global(.MuiPickersDay-root.Mui-selected.MuiPickersDay-today.Mui-disabled) {
    background-color: transparent;
    color: colors.$grey-500;
  }

  // disabled
  :global(.MuiPickersDay-root.Mui-disabled) {
    background-color: transparent;
    color: colors.$grey-500;
    font-weight: normal;
  }
  :global(.MuiPickersDay-root.Mui-disabled:after) {
    background-color: colors.$grey-500;
  }

  // DateRangeCalendar
  :global(.MuiDateRangePickerDay-root) {
    :global(.MuiPickersDay-root) {
      height: $LABEL_DIMENSIONS + px;
      width: $LABEL_DIMENSIONS + px;
    }

    // Preview selection
    :global(.MuiDateRangePickerDay-outsideCurrentMonth .MuiDateRangePickerDay-rangeIntervalDayPreview) {
      background-color: transparent;
    }

    :global(.MuiDateRangePickerDay-rangeIntervalDayPreview),
    :global(.MuiDateRangePickerDay-rangeIntervalDayPreview.MuiDateRangePickerDay-rangeIntervalDayPreviewEnd),
    :global(.MuiDateRangePickerDay-rangeIntervalDayPreview.MuiDateRangePickerDay-rangeIntervalDayPreviewStart),
    :global(.MuiDateRangePickerDay-rangeIntervalDayPreview .MuiButtonBase-root:not(.Mui-selected)) {
      background-color: lighten(colors.$primary, 32%);
      border-color: lighten(colors.$primary, 32%);
      color: colors.$primary;
    }

    &.MuiDateRangePickerDay-outsideCurrentMonth {
      .MuiDateRangePickerDay-rangeIntervalDayPreview {
        border-color: transparent;
      }
    }

    &:last-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview {
      border-right-color: colors.$grey-400;
    }
    &:first-of-type .MuiDateRangePickerDay-rangeIntervalDayPreview {
      border-left-color: colors.$grey-400;
    }
  }

  :global(.MuiDateRangePickerDay-rangeIntervalDayHighlight) {
    background-color: colors.$primary;

    & :global(.MuiPickersDay-root.MuiButtonBase-root) {
      background-color: colors.$primary;
      border-color: colors.$primary;
      color: colors.$white;

      &:global(.MuiPickersDay-today:after) {
        background-color: colors.$white;
      }

      &:hover {
        background-color: lighten(colors.$primary, 10%);
      }
    }
  }

  :global(.MuiDateRangePickerDay-rangeIntervalDayHighlight.MuiDateRangePickerDay-hiddenDayFiller) {
    background-color: transparent;
  }
  :global(
      .MuiDayCalendar-slideTransition:not(.MuiDateRangeCalendar-dayDragging)
        .MuiDateRangePickerDay-dayOutsideRangeInterval:hover
    ) {
    border-color: lighten(colors.$primary, 30%);
    border-width: 2px;
  }

  :global(.MuiDateRangePickerDay-rangeIntervalPreview) {
    border: none;
  }

  &--micro {
    margin: 0;
    min-width: 220px;

    :global(.MuiPickersCalendarHeader-label) {
      font-size: 14px;
    }

    :global(.MuiDayCalendar-header .MuiDayCalendar-weekDayLabel) {
      width: 30px;
      height: 30px;
      margin: 0;
    }

    :global(.MuiButtonBase-root.MuiPickersDay-root),
    :global(.MuiPickersDay-root.MuiPickersDay-hiddenDaySpacingFiller) {
      width: 30px;
      height: 30px;
      margin: 0;
    }

    :global(.MuiDateCalendar-root),
    :global(.MuiDateRangeCalendar-root) {
      :global(.MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition) {
        min-height: 200px;
        min-width: 220px;
      }
    }

    :global(.MuiDateRangePickerDay-day) {
      transform: scale(1);
    }

    :global(.MuiPickersDay-root.MuiPickersDay-today:after) {
      bottom: 2px;
    }
  }
}
