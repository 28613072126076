@use "sass:color";

$black: #000;
$white: #fff;

$primary: rgb(82, 99, 243);
$primary-light: rgb(116, 130, 245);
$primary-dark: darken($primary, 15%);

$secondary: rgb(238, 154, 178);

$corn: rgb(255, 197, 80);
$corn-light: lighten($corn, 20%);
$corn-200: rgba(255, 197, 80, 0.2);
$shrimp: $secondary;

$success: #02b345;

$success-light: #e6f3eb;
$success-main: $success;
$success-dark: #015c23;

$error: rgb(217, 3, 3);

$error-main: #b67777;
$error-light: rgb(246, 213, 213);
$error-dark: #751919;

$warning-main: #f3cb64;
$warning-light: #f6efc8;
$warning-dark: #7e590a;
$warning-text: rgb(98, 95, 80);

$soft-grey: #eaebf0;

$divider: rgba(0, 0, 0, 0.075);
$background: #f7f8fc;

$darkness: rgb(23, 29, 38);
$darkness-100: rgb(232, 232, 233);

$border-grey: rgba($darkness, 0.075);

$bg-grey: #ebecf0;

$blurple: rgb(82, 99, 243);
$blurple-100: color.scale($blurple, $lightness: 90%);
$blurple-200: color.scale($blurple, $lightness: 80%);
$blurple-300: color.scale($blurple, $lightness: 70%);
$blurple-400: color.scale($blurple, $lightness: 60%);

$text-grey: #757575;

// TODO: Figure out a mixin for this shading
$grey: #9e9e9e;
$grey-50: #fafafa;
$grey-100: #f5f5f5;
$grey-200: $soft-grey;
$grey-300: #e0e0e0;
$grey-400: #bdbdbd;
$grey-500: $grey;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$text-primary: rgba($black, 0.87);
$text-secondary: rgba($black, 0.6);
$text-disabled: rgba($black, 0.38);

$text-light: $grey-600;

$stats-midnight: #151f8b;
$stats-blurple: $primary;
$stats-cornflower: #7d8aff;
$stats-cheese: #e2b351;
$stats-corn: $corn;
$stats-butter: #ffe583;
$stats-pink: #f8c3d3;
$stats-shrimp: $shrimp;
$stats-salmon: #ce7690;
$stats-grey: $grey-200;

// For JS Imports
$exports: (
  // SASS doesn't understand that these are JS-exports, and
  // is worried about using identifiers which have
  // significance in CSS.  Using SCSS interpolation clears
  // the error from compile.
  #{black}: $black,
  #{white}: $white,
  corn: $corn,
  cornLight: $corn-light,
  corn200: $corn-200,
  shrimp: $shrimp,
  blurple: $blurple,
  blurple100: $blurple-100,
  blurple200: $blurple-200,
  blurple300: $blurple-300,
  blurple400: $blurple-400,
  primary: $primary,
  primaryLight: $primary-light,
  secondary: $secondary,
  primaryDark: $primary-dark,
  error: $error,
  errorMain: $error-main,
  errorLight: $error-light,
  errorDark: $error-dark,
  warningMain: $warning-main,
  warningLight: $warning-light,
  warningDark: $warning-dark,
  successMain: $success-main,
  successLight: $success-light,
  successDark: $success-dark,
  softGrey: $soft-grey,
  divider: $divider,
  background: $background,
  textPrimary: $text-primary,
  textSecondary: $text-secondary,
  textDisabled: $text-disabled,
  textLight: $text-light,
  darkness: $darkness,
  darkness100: $darkness-100,
  borderGrey: $border-grey,
  textGrey: $text-grey,
  #{grey}: $grey,
  grey50: $grey-50,
  grey100: $grey-100,
  grey200: $grey-200,
  grey300: $grey-300,
  grey400: $grey-400,
  grey500: $grey-500,
  grey600: $grey-600,
  grey700: $grey-700,
  grey800: $grey-800,
  grey900: $grey-900,
  statsMidnight: $stats-midnight,
  statsBlurple: $stats-blurple,
  statsCornflower: $stats-cornflower,
  statsCheese: $stats-cheese,
  statsCorn: $stats-corn,
  statsButter: $stats-butter,
  statsPink: $stats-pink,
  statsShrimp: $stats-shrimp,
  statsSalmon: $stats-salmon,
  statsGrey: $stats-grey,
  bg-grey: $bg-grey
);
